import React, { useState, useEffect } from 'react'
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import Time from "../utils/time"
import ReactMoment from 'react-moment'
import 'moment-timezone'
import moment from 'moment'
import styled from 'styled-components'  
import sanitizeHtml from 'sanitize-html'
import { 
  type_body, 
  type_body_bold, 
  breakpoint, 
  blackColor, 
  greyColor, 
  typeBody, 
  typeDate, 
  typeCloseBtn, 
  grid, 
  colorTransition, 
  blurTransition, 
  TextWrap, 
  footerHeight,
  footerHeightMobile
} from '../styles/helpers'
import { 
  EventHeader,
  EventDate,
  EventTitle,
  EventTimetable,
  EventTimetableEntry,
  EventDetail
} from '../styles/timeline'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Text from '../components/text'
import SEO from "../components/seo"
moment.tz.setDefault('Europe/Amsterdam')


const EventGrid = styled.div`
  ${grid};
  height: 100%;
  width: 100%;
  position: relative;
`

const EventGridInner = styled.div`
  grid-column-start: 2;
  grid-column-end: 12;
  @media ${breakpoint.xs} {
    grid-column-start: 3;
    grid-column-end: 11;
  }
`

// 33.3% = 4cols
// margin top +2px to account for border
const EventPage = styled.div`
  position: relative;
  top: 0;
  width: 33.3%;
  z-index: 3;
  margin: calc(4rem + 2px) 0 2rem 0;
  transition: ${blurTransition};
  filter: ${props => (props.blurred ? 'blur(5px)' : 'none')};
  left: ${props => (props.currentMonth === 1 ? 
    `16.6%` : props.currentMonth === 2 ?
    '24.9%' : props.currentMonth === 3 ?
    '33.2%' : props.currentMonth === 4 ?
    '41.5%' : props.currentMonth === 5 ?
    '49.8%' : props.currentMonth === 6 ?
    '58.1%' : props.currentMonth === 7 ?
    '16.6%' : props.currentMonth === 8 ?
    '24.9%' : props.currentMonth === 9 ?
    '33.2%' : props.currentMonth === 10 ?
    '41.5%' : props.currentMonth === 11 ?
    '49.8%' : props.currentMonth === 12 ? 
    '58.1%' : 0
  )};
  @media ${breakpoint.xs} {
    width: 100%;
    left: 0 !important;
  }
`

const EventWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding-right: ${props => (props.currentMonth > 6 ? '1rem' : 'unset')};
  @media ${breakpoint.xs} {
    padding-bottom: 2rem;
    padding-right: unset;
  }
`

const EventCloseBtn = styled.div`
 & a {
   color: black;
   transition: ${colorTransition};
   ${type_body};
   color: ${blackColor};
   @media ${breakpoint.xs} {
    font-size: 100%;
   }
   @media (hover: hover) {
    &:hover {
      color: ${greyColor};
    }
  }
 }
`

export const query = graphql`
  query($slug: String) {
    sanityEvent(slug: { current: { eq: $slug } }) {
      title
      detail
      id
      date
      description {
        _key
        _type
        style
        list
        _rawChildren
        children {
          text
        }
      }
      _rawDescription(resolveReferences: {maxDepth: 5})
      project {
        slug {
          _key
          _type
          current
        }
        year
        title
        _id
        _key
        thumbnail {
          asset {
            fixed(width: 1200, height: 628) {
              src
              base64
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`

export default ({ data, location, ...props }) => {

  useEffect(() => {
    const eventYear = Time.parseYear(data.sanityEvent.date)
    const eventMonth = Time.parseMonth(data.sanityEvent.date)
    const eventDay = Time.parseDay(data.sanityEvent.date)

    if (eventMonth !== props.currentMonth) {
      // throw to bottom of stack
      // todo: do this in better way (e.g. check .pathname in <Layout /> effect instead)
      setTimeout(() => {
        props.setCurrentMonth(eventMonth)
      }, 100)
    }
    if (eventYear !== props.currentYear) {
      // throw to bottom of stack
      // todo: do this in better way (e.g. check .pathname in <Layout /> effect instead)
      setTimeout(() => {
        props.setCurrentYear(eventYear)
      }, 100)
    }
  },[])

 
  const body = data.sanityEvent._rawDescription ? <Text info={false} data={data.sanityEvent._rawDescription} background={false} /> : false
  const returnLink = (path) => { return '/projects/' + path }  

  // const seoTitle = data.sanityEvent.subtitle !== null ? data.sanityEvent.title + ',' + ' ' + data.sanityEvent.subtitle : data.sanityEvent.title
  // todo: update seoDescription
  const seoDescription = data.sanityEvent.description !== null ? data.sanityEvent.description[0].children[0].text : data.sanityEvent.title
  const seoImage = data.sanityEvent.project !== null ? data.sanityEvent.project.thumbnail !== null ? data.sanityEvent.project.thumbnail.asset.fixed.src : false : false
  const seoComponent = !seoImage ? <SEO title={data.sanityEvent.title} description={seoDescription} /> : <SEO title={data.sanityEvent.title} description={seoDescription} image={seoImage} />

  const eventHeader = props.mobile ? 
  <EventHeader pointerEvents={false} isPast={false}>
    <EventDate>
      <ReactMoment format="DD.MM.YYYY, HH:mm">
        {data.sanityEvent.date}
      </ReactMoment>
    </EventDate>
    <EventTitle>{data.sanityEvent.title}</EventTitle>
    {data.sanityEvent.detail !== null ? <EventDetail>{sanitizeHtml(data.sanityEvent.detail)}</EventDetail> : false}
  </EventHeader>
  : 
  false

  return (
    <>
    {seoComponent}
    <EventGrid>
      <EventGridInner>
        <EventPage blurred={false} currentMonth={props.currentMonth}>
          <EventWrapper currentMonth={props.currentMonth}>
            {/* event header is conditional: only for mobile */}
            {eventHeader}
            {body}
            <EventCloseBtn>
              <Link to = '/'>← Return</Link> 
            </EventCloseBtn>
          </EventWrapper>
        </EventPage>
      </EventGridInner>
    </EventGrid>
    </>
  )
}
